import React from 'react'

import Slider from "react-slick";
import { Avatar, Button, Col, Row } from 'antd'
import { Message, Star, Setting, Category, Graph } from "react-iconly";
import { Discovery } from 'react-iconly';

export default function LandingFeatures() {
    const featuresCard = [
        {
            icon: <Discovery set="bold" style={{ width: 24, height: 24, marginLeft: 0 }} />,
            title: "Graphical Flow Builder",
            subTitle: `Drag-and-drop interface to create decision trees for your AI assistant. Support for branching to enable dynamic interactions with users.`,
        },
        {
            icon: <Star set="bold" style={{ width: 24, height: 24, marginLeft: 0 }} />,
            title: "Knowledge Base Management",
            subTitle: "Ability to import, organize, and update the knowledge base with user-defined information.",
        },
        {
            icon: <Setting set="bold" style={{ width: 24, height: 24, marginLeft: 0 }} />,
            title: "Context Awareness",
            subTitle: "Contextual memory to maintain the context of the conversation and provide more personalized responses.",
        },
        {
            icon: <Category set="bold" style={{ width: 24, height: 24, marginLeft: 0 }} />,
            title: "One Account and Wallet",
            subTitle: "Build and use multiple assistants from a single account and wallet and have them sync with our messenger",
        },
        {
            icon: <Graph set="bold" style={{ width: 24, height: 24, marginLeft: 0 }} />,
            title: "Marketplace",
            subTitle: "Dont have access to the data you need? Find pre-built assistants in our marketplace trained on licensed materials",
        },
    ];

    const featuresCardMap = featuresCard.map((value, index) => (
        <div key={index} className="hp-px-sm-8 hp-px-16">
            <div className="hp-border-radius hp-bg-black-10 hp-bg-dark-100 hp-p-18">
                <Row gutter={[20]}>
                    <Col>
                        <Avatar className="hp-bg-black-0 hp-bg-dark-90" size={55} icon={value.icon} />
                    </Col>

                    <Col flex="1 0 0">
                        <h4 className="hp-font-weight-500 hp-mb-8">{value.title}</h4>
                        <p className="h5 hp-font-weight-500 hp-text-color-black-80 hp-text-color-dark-30 hp-mb-0">{value.subTitle}</p>
                    </Col>
                </Row>
            </div>
        </div>
    ));

    return (
        <section className="hp-landing-features hp-pt-24" style={{paddingBottom:'200px', paddingTop: '200px'}}>
            <Row align="middle" justify="center">
                <Col xl={10} lg={14} md={16} span={24} className="hp-px-16 hp-text-center hp-mb-sm-64 hp-mb-96">
                    <h2 className="h1 hp-mb-32">
                        Built With Amazing Features
                    </h2>

                    <p className="h5 hp-font-weight-400 hp-text-color-black-80 hp-text-color-dark-30">
                    Swiftly develop AI assistants for various purposes, or construct your own from scratch. 
                    </p>
                </Col>

                <Col span={24}>
                    <Slider
                        dots={false}
                        infinite={true}
                        slidesToShow={3}
                        slidesToScroll={1}
                        arrows={false}
                        speed={800}
                        autoplay={true}
                        autoplaySpeed={4000}
                        centerMode={true}
                        centerPadding={230}
                        responsive={[
                            {
                                breakpoint: 1370,
                                settings: {
                                    centerPadding: 150
                                }
                            },
                            {
                                breakpoint: 1199,
                                settings: {
                                    centerPadding: 80,
                                    slidesToShow: 2,
                                }
                            },
                            {
                                breakpoint: 767,
                                settings: {
                                    centerPadding: 24,
                                    slidesToShow: 1,
                                }
                            }
                        ]}
                    >
                        {featuresCardMap}
                    </Slider>
                </Col>

                {/*<Col span={24} className="hp-px-16 hp-text-center hp-mt-sm-32 hp-mt-64">
                    <Button type="primary">Learn More</Button>
                </Col>*/}
            </Row>
        </section>
    )
}
