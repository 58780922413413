import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setLoggeedInUser(user);
        } else {
          localStorage.removeItem("authUser");
        }
      });
    }
  }

  /*Retrieves and sets settigns from firebase    */

 /**
   * Registers the user with given details
   */
  registerUser = (email, password, username) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (user) => {
            let userL = this.addNewSimpleUserToFirestore(user, email, username);
            userL = JSON.stringify(userL);
            resolve(userL);
            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * Registers the user with given details
   */
   /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {

            const uid = user.user.uid;
            
            const currentUser = JSON.stringify(firebase.auth().currentUser);
            resolve(currentUser);
          
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Social Login user with given details
   */
    addNewSimpleUserToFirestore = (user, email, username) => {
    const collection = firebase.firestore().collection("users");

    const details = {
      email: email,
    
      firstName: username,
      creditBalance: 0,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      profileImg:'https://firebasestorage.googleapis.com/v0/b/hive-50f55.appspot.com/o/empty_user.png?alt=media&token=ac78c97a-050e-413e-b7e0-c370f959eee9'
    
    };
    collection.doc(firebase.auth().currentUser.uid).set(details);
    return { user, details };
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

const setLoggeedInUser = (user) => {
  localStorage.setItem("authUser", JSON.stringify(user));
};

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend, setLoggeedInUser };