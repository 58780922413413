import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ConfigProvider } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from './languages';

import Router from "./router/Router";

import { initFirebaseBackend } from './helpers/firebase_helpers';


const firebaseConfig = {
  apiKey: "AIzaSyDVCW6j43a-gytedtKmRPEYTx4jnfFNPPI",
  authDomain: "hive-50f55.firebaseapp.com",
  projectId: "hive-50f55",
  storageBucket: "hive-50f55.appspot.com",
  messagingSenderId: "101732698929",
  appId: "1:101732698929:web:7d9cde21f3dc8a4b50be10",
  measurementId: "G-12MVC2N5P0"
};

// // init firebase backend
initFirebaseBackend(firebaseConfig);

export default function App() {
  // Redux
  const customise = useSelector(state => state.customise)

  // Lang
  const currentAppLocale = AppLocale[customise.language];

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", customise.language);
  }, [customise]);

  return (
    <ConfigProvider locale={currentAppLocale.antd} direction={customise.direction}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Router />
      </IntlProvider>
    </ConfigProvider>
  );
}